import styled from '@emotion/styled'
import { FC, useMemo } from 'react'

import { useAppSelector } from '@/store/hooks'
import { selectRemote } from '@/store/slices/app.slice'

const IframeView: FC<{ routePath?: string }> = ({ routePath }) => {
  const props = useAppSelector(selectRemote)

  const src = useMemo(
    () => `http://${props.remote}/${routePath?.includes('.html') ? routePath : `#/${routePath}`}?token=${props.token}`,
    [props, routePath]
  )

  return <Iframe title='inner' src={src}></Iframe>
}

const Iframe = styled.iframe`
  width: 100%;
  height: calc(100vh);
  overflow: hidden;
  background-color: transparent;
  border: 0;
  box-sizing: border-box;
`

export default IframeView
